/*eslint-disable */
// import TprepSessionModel from '../../Model/TprepSession'
import request from '../../Utils/curl'
import Utility from "../../Utils/utility";

let TprepQuiz =  {
  /**
   * tprep_quizList
   */
  async tprep_quizList (context, whereFilter=null) {
    try{
      Utility.showLoader = true;
      Utility.loadingMsg = "Please wait....";

      let post_data = new FormData();  
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "app_list_console", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at tprep_quizList() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },

  /**
   * tprep_sessionView
   */
  async tprep_sessionView (context, tprepSessionId) {
    try {      
      let post_data = new FormData();
      post_data.append('tps_id', tprepSessionId);
      return await request.curl(context, "tprep_session_view", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at tprep_sessionView() and Exception:",err.message)
    }    
  },

  /**
   * tprep_sessionAdd
   */
  async tprep_sessionAdd (context, tprep_sessionObj) {
    try{
      let post_data = new FormData();
    
      for (let key in tprep_sessionObj) {
        post_data.append(key, tprep_sessionObj[key]);
      }

      return await request.curl(context, "tprep_session_add", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at tprep_sessionAdd() and Exception:',err.message)
    }
  },

  /**
   * tprep_sessionEdit
   */
  async tprep_sessionEdit (context, tprep_sessionObj) {
    try{
      let post_data = new FormData();
    
      for (let key in tprep_sessionObj) {
        post_data.append(key, tprep_sessionObj[key]);
      }

      return await request.curl(context, "tprep_session_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at tprep_sessionEdit() and Exception:',err.message)
    }
  },

  /**
   * tprep_sessionDelete
   */
  async tprep_sessionDelete (context, tprepSessionId) {
    try{
      let post_data = new FormData();
      
      post_data.append('tps_id', tprepSessionId);

      return await request.curl(context, "tprep_session_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at tprep_sessionDelete() and Exception:',err.message)
    }
  }
}

export {
  TprepQuiz
}
